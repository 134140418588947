import { Component, inject, OnInit } from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';
import { RouterLink } from '@angular/router';

import { entry } from '../../todoentry';
import { pushobj } from '../../pushObj';
import { TodosService } from '../../shared/services/todos.service';
import { PushUpdatesService } from '../../shared/services/push-updates.service';

@Component({
  selector: 'app-todo-history',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
  ],
  templateUrl: './todo-history.component.html',
  styleUrl: './todo-history.component.css'
})

export class TodoHistoryComponent implements OnInit {
  ToDoEntryList : entry[] = [];
  todosService: TodosService = inject(TodosService);
  pushService: PushUpdatesService = inject(PushUpdatesService);

  ngOnInit(): void {
    this.todosService.getClosedToDoEntries().subscribe((data) => {
      this.ToDoEntryList = data;
    });

    this.pushService.pushUpdates().subscribe((data: pushobj) => {
      switch (data.action) {
        case "delete": {
          this.deleteEntryInList(data.payload_str)
          break;
        }
      }
    });
  }

  deleteClosedToDos() {
    this.todosService.deleteAllClosedToDoEntries().subscribe()
  }

  deleteEntryInList(id: string) {
    if (id === 'all') {
      this.ToDoEntryList = [];
    }
  }
}
