import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { entry } from '../../todoentry';

@Injectable({
  providedIn: 'root'
})
export class TodosService {
  todoURL = '/api/data/';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  versionOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
      
    } ),responseType: 'text' as 'json'
  };
  
  constructor(private http: HttpClient) { }

  getAllToDoEntries(sort: string): Observable<entry[]> {
    const modifiedURL = `${this.todoURL}all/${sort}`;
    return this.http.get<entry[]>(modifiedURL);
  }

  getClosedToDoEntries(): Observable<entry[]> {
    const modifiedURL = `${this.todoURL}closed`;
    return this.http.get<entry[]>(modifiedURL);
  }

  getToDoEntryByID(id: string): Observable<entry> {
    const modifiedURL = `${this.todoURL}id/${id}`;
    return this.http.get<entry>(modifiedURL);
  }

  public getFrontendVersion(): Observable<any> {
    return this.http.get<any>("/assets/version.txt", this.versionOptions);
  }

  getBackendVersion(): Observable<any> {
    const modifiedURL = `${this.todoURL}version`;
    return this.http.get<any>(modifiedURL, this.versionOptions);
  }

  updateToDoEntry(entry: entry): Observable<any> {
    const modifiedURL = `${this.todoURL}update`;
    return this.http.put<entry>(modifiedURL, entry, this.httpOptions);
  }

  addToDoEntry(entry: entry): Observable<any> {
    const modifiedURL = `${this.todoURL}add`;
    return this.http.put<entry>(modifiedURL, entry, this.httpOptions);
  }

  deleteAllClosedToDoEntries(): Observable<string> {
    return this.http.delete<string>(this.todoURL);
  }

  closeAllSolvedToDoEntries(date: string): Observable<string> {
    const modifiedURL = `${this.todoURL}archive`;
    return this.http.put<string>(modifiedURL, {closedDate : date}, this.httpOptions);
  }

  closeToDoEntryByID(id: string, date: string): Observable<string> {
    const modifiedURL = `${this.todoURL}archive/${id}`;
    return this.http.put<string>(modifiedURL, {closedDate : date}, this.httpOptions);
  }
}
