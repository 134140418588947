<div class="p-2 d-flex justify-content-center">
    <div class="container">
        <div class="d-inline" *ngIf="entryUpdate">
            <h1>Details of "{{entry.name}}"</h1>
        </div>
        <div class="d-inline" *ngIf="!entryUpdate">
            <h1>Create a new ToDo-Entry</h1>
        </div>
        <form [formGroup]="entryForm">
            <label for="entry_name" class="form-label">Name:</label>
            <input id="entry_name" class="form-control mb-3" type="text" formControlName="entryName">
            <label for="entry_desc" class="form-label">Description:</label>
            <textarea id="entry_desc" class="form-control mb-3" formControlName="entryDesc"></textarea>
            <hr>
            <p class="m-0">ToDo Priority:</p>
            <input type="radio" class="btn-check" name="entryPrio" 
            id="option1" autocomplete="off" value="1" formControlName="entryPrio"
            [checked]="entry.priority == 1">
            <label class="btn mt-1 btn-outline-danger" for="option1">
                <i class="ri-error-warning-line ri-xl align-middle"></i>
                <span class="align-middle ms-1">Important</span>
            </label>
            <br>
            <input type="radio" class="btn-check" name="entryPrio" 
            id="option2" autocomplete="off" value="2" formControlName="entryPrio"
            [checked]="entry.priority == 2">
            <label class="btn mt-1 btn-outline-warning" for="option2">
                <i class="ri-subtract-line ri-xl align-middle"></i>
                <span class="align-middle ms-1">Normal</span>
            </label>
            <br>
            <input type="radio" class="btn-check" name="entryPrio" 
            id="option3" autocomplete="off" value="3" formControlName="entryPrio"
            [checked]="entry.priority == 3">
            <label class="btn mt-1 btn-outline-primary" for="option3">
                <i class="ri-arrow-down-line ri-xl align-middle"></i>
                <span class="align-middle ms-1">Low</span>
            </label>
        </form>
        <hr>
        <div *ngIf="entryUpdate">
            <p>Created at: {{entry.createdDate}}</p>
            <p>Last Update: {{entry.updatedDate}}</p>
            <p>Solved at: {{entry.solvedDate}}</p>
        </div>
        <button 
        class="btn btn-outline-primary p-2 me-2" 
        (click)="goBack()">
            <i class="ri-arrow-go-back-line ri-xl"></i> Go Back
        </button>
        <div class="d-inline" *ngIf="entryUpdate">
            <button 
            class="btn btn-outline-success p-2 ms-1 me-2" 
            (click)="updateEntry()">
                <i class="ri-loop-left-line ri-xl"></i> Update
            </button>
        </div>
        <div class="d-inline" *ngIf="!entryUpdate">
            <button 
            class="btn btn-outline-success p-2 ms-1 me-2" 
            (click)="addEntry()">
                <i class="ri-save-3-line ri-xl"></i> Save
            </button>
        </div>
        <div class="d-inline" *ngIf="entryUpdate">
            <button 
            class="btn btn-outline-danger p-2 ms-1" 
            (click)="closeEntry()">
                <i class="ri-close-line ri-xl"></i> Close
            </button> 
        </div>
    </div>
</div>
