<header class="bg-primary-subtle p-2 ">
    <a class="text-decoration-none link-primary me-2 fs-3" routerLink="/">
        <span><i class="ri-todo-line ri-xl"></i> {{ title | uppercase }}</span>
    </a>
    <a class="text-decoration-none link-primary ms-2 fs-5" routerLink="/history">
        <span><i class="ri-history-line ri-xl"></i> History</span>
    </a>
</header>

<main class="bg-body-secondary">
    <router-outlet></router-outlet>
</main>

<footer class="bg-primary-subtle p-2">
  <p class="align-middle m-0">
    SPF &commat; reflact AG
    (Frontend: {{ version_frontend }} | Backend: {{ version_backend }})
</p>
</footer>