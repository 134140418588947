import { Component, inject } from '@angular/core';

import { TodosService } from './shared/services/todos.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'todo-list';
  version_backend: string = "";
  version_frontend: string = "";
  todosService = inject(TodosService);

  constructor() {
    this.todosService.getBackendVersion().subscribe(data => {
      console.log(data);
      this.version_backend = data;
    })

    this.todosService.getFrontendVersion().subscribe(data => {
      console.log(data);
      this.version_frontend = data;
    })
  }
}
