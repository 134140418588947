<div class="p-2 d-flex justify-content-center">
    <div class="container-xs">
        <form [formGroup]="loginForm">
            <label for="login_name" class="form-label">Name:</label>
            <input id="login_name" class="form-control mb-3" type="text" formControlName="loginName">
            <button 
            class="btn btn-outline-primary p-2 ms-1" 
            (click)="tryLogin()">
                <i class="ri-login-box-line ri-xl"></i> Login
            </button> 
        </form>
    </div>
</div>