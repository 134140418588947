import { Component, inject } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { LoginService } from 'src/app/shared/services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-todo-login',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './todo-login.component.html',
  styleUrl: './todo-login.component.scss'
})
export class TodoLoginComponent {
  loginService = inject(LoginService);

  loginForm = new FormGroup({
    loginName: new FormControl()
  });

  constructor(private router: Router) {  }

  tryLogin() {
    this.loginService.loginUser(this.loginForm.value.loginName).subscribe(data => {
      console.log(data);
      this.router.navigateByUrl('/');
    })
  }
}
