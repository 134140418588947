<div class="p-2 d-flex justify-content-center">
    <div class="container">
        <a class="btn btn-outline-success p-2" routerLink="/detail">
            <i class="ri-add-circle-line ri-xl"></i> New ToDo
        </a>
        <div class="row gx-0">
            <div class="col-10">
                <p class="my-2 text-decoration-underline">Unsolved ToDo's:</p>
            </div>
            <div class="col-2">
                <select class="form-select" (change)="changeSortedEntryList(sorting.value)" #sorting>
                    <option *ngFor="let sort of sort_list" [value]="sort.value" [selected]="sort.value == 'Prio_ASC'" >
                        {{ sort.name }}
                    </option>
                </select>
            </div>
        </div>
        <ng-container *ngFor="let entry of ToDoEntryList">
            <div *ngIf="entry.solved == false">
                <ng-container *ngTemplateOutlet="entryTpl;  context: {entry}"></ng-container>
            </div>
        </ng-container>
        <br>
        <p class="my-2 text-decoration-underline">Solved ToDo's:</p>
        <ng-container *ngFor="let entry of ToDoEntryList">
            <div *ngIf="entry.solved == true">
                <ng-container *ngTemplateOutlet="entryTpl; context: {entry}"></ng-container>
            </div>
        </ng-container>
        <button class="btn btn-outline-danger p-2 mt-2 me-2" (click)="closeAllSolvedToDos()">
            <i class="ri-close-line ri-xl"></i> Solved ToDo's
        </button>
    </div>
</div>

<ng-template #entryTpl let-entry="entry">
    <div class="container my-1 p-1 bg-body-tertiary rounded text-truncate">
        <input type="checkbox" name="checkbox-{{entry._id}}"
        [checked]="entry.solved" class="align-middle form-check-input mt-0 ms-1"
        (change)="changeStatus(entry)">
        <ng-container *ngIf="!entry.solved  && entry.priority == 1">
            <i class="ri-error-warning-line ri-xl align-middle ms-1 text-danger"></i>
        </ng-container>
        <ng-container *ngIf="!entry.solved && entry.priority == 2">
            <i class="ri-subtract-line ri-xl align-middle ms-1 text-warning"></i>
        </ng-container>
        <ng-container *ngIf="!entry.solved && entry.priority == 3">
            <i class="ri-arrow-down-line ri-xl align-middle ms-1 text-primary"></i>
        </ng-container>
        <button *ngIf="entry.solved" class="btn p-0 ms-1" (click)="closeEntry(entry._id)">
            <i class="ri-close-line ri-xl text-danger"></i>
        </button> 
        <a routerLink="/detail/{{entry._id}}"
        class="text-decoration-none text-primary-emphasis fs-5 align-middle">
            <span> {{ entry.name }}</span>
        </a>
    </div>
</ng-template>



