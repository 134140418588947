import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io } from 'socket.io-client';

import { entry } from '../../todoentry';
import { pushobj } from '../../pushObj';

@Injectable({
  providedIn: 'root'
})
export class PushUpdatesService {
  private ioSocket = io();

  constructor() { }

  pushUpdates() {
    return new Observable<pushobj>(observer => {
      this.ioSocket.on('push', (data) => {
        observer.next(data);
      });
    });
  }
}
