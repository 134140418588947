<div class="p-2 d-flex justify-content-center">
    <div class="container">
        <h1 class="my-2 text-decoration-underline">Closed ToDo-Entries:</h1>
        <div class="accordion" id="accordionHistory" *ngFor="let entry of ToDoEntryList; index as i">
            <ng-container *ngTemplateOutlet="entryTpl; context: {entry, i}"></ng-container>
        </div>
        <button class="btn btn-outline-danger p-2 mt-2 me-2" (click)="deleteClosedToDos()">
            <i class="ri-delete-bin-2-line ri-xl"></i> Delete listed ToDo's
        </button>
    </div>
</div>

<ng-template #entryTpl let-entry="entry" let-i="i">
    <div class="accordion-item text-truncate">
        <h2 class="accordion-header">
            <button class="accordion-button" type="button"
            data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne'+i"
            aria-expanded="false" [attr.aria-controls]="'collapseOne'+i">
                <ng-container *ngIf="entry.priority == 1">
                    <i class="ri-error-warning-line ri-xl align-middle text-danger"></i>
                </ng-container>
                <ng-container *ngIf="entry.priority == 2">
                    <i class="ri-subtract-line ri-xl align-middle text-warning"></i>
                </ng-container>
                <ng-container *ngIf="entry.priority == 3">
                    <i class="ri-arrow-down-line ri-xl align-middle text-primary"></i>
                </ng-container>
                <span class="ms-1"> {{ entry.name }}</span>
            </button>
        </h2>
        <div id="collapseOne{{i}}" class="accordion-collapse collapse" data-bs-parent="#accordionHistory">
            <div class="accordion-body">
                <p>Description:</p>
                <p>{{ entry.name }}</p>
                <p>Created at: {{entry.createdDate}}</p>
                <p>Last Update: {{entry.updatedDate}}</p>
                <p>Solved at: {{entry.solvedDate}}</p>
                <p>Closed at: {{entry.closedDate}}</p>
            </div>
        </div>
    </div>
</ng-template>