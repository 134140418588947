import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule, Location, formatDate } from '@angular/common';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';

import { entry } from '../../todoentry';
import { TodosService } from '../../shared/services/todos.service';

@Component({
  selector: 'app-todo-detail',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './todo-detail.component.html',
  styleUrl: './todo-detail.component.css'
})
export class TodoDetailComponent {
  route: ActivatedRoute = inject(ActivatedRoute);
  todosService = inject(TodosService);
  entry: entry = {
    _id: "",
    solved: false,
    closed: false,
    solvedDate: "none",
    createdDate: "none",
    updatedDate: "none",
    closedDate: "none",
    description: "",
    name: "",
    priority: 0
  };
  entryUpdate: boolean | undefined;
  entryForm = new FormGroup({
    entryName: new FormControl(),
    entryDesc: new FormControl(),
    entryPrio: new FormControl()
  });

  constructor(private location: Location) {
    if (this.route.snapshot.params['id']) {
      this.entryUpdate = true;
      const entryID = this.route.snapshot.params['id'];
      this.todosService.getToDoEntryByID(entryID).subscribe((data) => {
        this.entry = data;
        this.entryForm.setValue({entryName: this.entry.name, entryDesc: this.entry.description, entryPrio: this.entry.priority});
      });
    } else {
      this.entryUpdate = false;
    }
  }

  setDateString() {
    return formatDate(Date.now(), 'YYYY-MM-dd:HH-mm-ss', 'en-EN');
  }

  addEntry() {
    let newEntry = {
      _id: "",
      name: this.entryForm.value.entryName,
      description: this.entryForm.value.entryDesc,
      solved: false,
      closed: false,
      createdDate: this.setDateString(),
      updatedDate: "none",
      solvedDate: "none",
      closedDate: "none",
      priority: this.entryForm.value.entryPrio
    }
    this.todosService.addToDoEntry(newEntry).subscribe();
    this.entryForm.patchValue({entryName: "", entryDesc: ""});
  }

  updateEntry() {
    let updateEntry = {
      _id: this.entry?._id,
      name: this.entryForm.value.entryName,
      description: this.entryForm.value.entryDesc,
      solved: this.entry?.solved,
      closed: false,
      createdDate: this.entry?.createdDate,
      updatedDate: this.setDateString(),
      solvedDate: this.entry?.solvedDate,
      closedDate: "none",
      priority: this.entryForm.value.entryPrio
    }
    this.todosService.updateToDoEntry(updateEntry).subscribe();
    this.goBack();
  }

  closeEntry() {
    this.todosService.closeToDoEntryByID(this.entry._id, this.setDateString()).subscribe();
    this.goBack()
  }

  goBack(): void {
    this.location.back();
  }
}
