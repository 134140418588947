import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  authURL = '/api/auth/';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  public token: string | undefined = undefined

  constructor(private http: HttpClient) {
    const token = localStorage.getItem('authToken');
    if (token != null) {
      this.token = token;
    }
  }

  public getAuthHeaders(): any {
    return {
      token: localStorage.getItem('authToken')
    };
  }

  public getAuthorizationToken(): string | undefined {
    if (this.token == undefined) {
      return undefined;
    }
    return 'Bearer ' + this.token;
  }

  public loginUser(user: string): Observable<any> {
    return this.http.post<any>(this.authURL+'login', {user: user}, this.httpOptions);
  }

  public logoutUser(): Observable<any> {
    return this.http.post<any>(this.authURL+'logout', {}, this.httpOptions);
  }

  public registerUser(user: string): Observable<any> {
    return this.http.post<any>(this.authURL+'register', {user: user}, this.httpOptions);
  }
}
