import { Component, inject, OnInit } from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';
import { RouterLink } from '@angular/router';

import { entry } from '../../todoentry';
import { pushobj } from '../../pushObj';
import { TodosService } from '../../shared/services/todos.service';
import { PushUpdatesService } from '../../shared/services/push-updates.service';

@Component({
  selector: 'app-todo-home',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
  ],
  templateUrl: './todo-home.component.html',
  styleUrl: './todo-home.component.css'
})

export class TodoHomeComponent implements OnInit {
  ToDoEntryList : entry[] = [];
  todosService: TodosService = inject(TodosService);
  pushService: PushUpdatesService = inject(PushUpdatesService);
  sort_list = [
    {value: "Name_ASC", name: "By Name (ASC)"}, 
    {value: "Name_DESC", name: "By Name (DESC)"}, 
    {value: "Prio_ASC", name: "By Prio (High to Low)"}, 
    {value: "Prio_DESC", name: "By Prio (Low to High)"}
  ];

  ngOnInit(): void {
    this.todosService.getAllToDoEntries("Prio_ASC").subscribe((data) => {
      this.ToDoEntryList = data;
    });

    this.pushService.pushUpdates().subscribe((data: pushobj) => {
      switch (data.action) {
        case "add": {
          this.ToDoEntryList.push(data.payload_json)
          break;
        }
        case "update": {
          this.updateEntryInList(data.payload_json)
          break;
        }
        case "close": {
          this.closeEntryInList(data.payload_str)
          break;
        }
      }
    });
  }

  setDateString() {
    return formatDate(Date.now(), 'YYYY-MM-dd:HH-mm-ss', 'en-EN');
  }

  changeSortedEntryList(sort: string) {
    this.todosService.getAllToDoEntries(sort).subscribe((data) => {
      this.ToDoEntryList = data;
    });
  }

  findPosInList(id: string) {
    for(let i=0; i<this.ToDoEntryList.length ; i++) {
      if (this.ToDoEntryList[i]._id === id) {
        return i;
      }
    }
    return -1;
  }

  updateEntryInList(entry: entry) {
    this.ToDoEntryList[this.findPosInList(entry._id)] = entry;
  }

  closeEntryInList(id: string) {
    if (id === 'solved') {
      this.ToDoEntryList = this.ToDoEntryList.filter((element) => element.solved == false);
    } else {
      let IndexOfEntry = this.findPosInList(id)
      this.ToDoEntryList = this.ToDoEntryList.filter((e, i) => i !== IndexOfEntry);
    }
  }

  changeStatus(entry: entry) {
    if (entry.solved) {
      entry.solved = false
      entry.solvedDate = "none";
    } else {
      entry.solved = true;
      entry.solvedDate = this.setDateString();
    }
    
    this.todosService.updateToDoEntry(entry).subscribe();
  }

  closeEntry(id: string) {
    this.todosService.closeToDoEntryByID(id, this.setDateString()).subscribe();
  }

  closeAllSolvedToDos() {
    this.todosService.closeAllSolvedToDoEntries(this.setDateString()).subscribe();
  }
}